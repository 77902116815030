<template>
  <PageTemplate>
		<Section image="theme-wm/marble.jpg">
			<Grid>
				<GridContainer size="12 7@md">
					<Card size="xlarge">
						<CardSection>
							<Heading size="6" uppercase>What we do</Heading>
							<Heading size="3">Build a Better Future for Your Company</Heading>
							<Paragraph>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut.</Paragraph>
							<Stack direction="column" align="left" space="3">
								<Paragraph>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut.</Paragraph>
								<Button label="View More" color="secondary" />
							</Stack>
						</CardSection>
					</Card>
				</GridContainer>
				<GridContainer size="12 5@md">
					<Stack visibility="hide show@md">
						<Images width="100%" height="100%" image="theme-wm/home2.jpg" />
					</Stack>
				</GridContainer>
			</Grid>
		</Section>
		<Section>
			<Grid>
				<GridContainer size="12 4@md">
					<Stack direction="column" align="left" space="3">
						<Stack direction="column" align="left" space="1">
							<Paragraph micro>01</Paragraph>
							<Heading size="5">Strategy work and acccounting</Heading>
							<Paragraph>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed Duis aute</Paragraph>
						</Stack>
						<Stack direction="column" align="left" space="1">
							<Paragraph micro>02</Paragraph>
							<Heading size="5">Pilot projects with product validation</Heading>
							<Paragraph>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed Duis aute</Paragraph>
						</Stack>
					</Stack>
				</GridContainer>
				<GridContainer size="12 4@md">
					<Stack direction="column" align="left" space="3">
						<Stack direction="column" align="left" space="1">
							<Paragraph micro>03</Paragraph>
							<Heading size="5">Operational management/process</Heading>
							<Paragraph>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed Duis aute</Paragraph>
						</Stack>
						<Stack direction="column" align="left" space="1">
							<Paragraph micro>04</Paragraph>
							<Heading size="5">Strategies with guaranteed success</Heading>
							<Paragraph>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed Duis aute</Paragraph>
						</Stack>
					</Stack>
				</GridContainer>
				<GridContainer size="12 4@md">
					<Stack direction="column" align="left" space="3">
						<Stack direction="column" align="left" space="1">
							<Paragraph micro>05</Paragraph>
							<Heading size="5">Expertise advice for your business plans</Heading>
							<Paragraph>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed Duis aute</Paragraph>
						</Stack>
						<Stack direction="column" align="left" space="1">
							<Paragraph micro>06</Paragraph>
							<Heading size="5">Unique and modern business tips</Heading>
							<Paragraph>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed Duis aute</Paragraph>
						</Stack>
					</Stack>
				</GridContainer>
			</Grid>
		</Section>
		<Section>
			<Heading size="2">Our Team</Heading>
			<Grid>
				<GridContainer size="12 6@sm 3@md">
					<Stack direction="column" align="left" space="1">
						<Images width="100%" height="300px" image="theme-wm/staff4.jpg" />
						<Stack direction="column" align="left">
							<Heading size="5">Cory Warner</Heading>
							<Heading size="6" uppercase>Owner/Founder</Heading>
						</Stack>
					</Stack>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Stack direction="column" align="left" space="1">
						<Images width="100%" height="300px" image="theme-wm/staff6.jpg" />
						<Stack direction="column" align="left">
							<Heading size="5">Keegan Tanner</Heading>
							<Heading size="6" uppercase>Co-Founder</Heading>
						</Stack>
					</Stack>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Stack direction="column" align="left" space="1">
						<Images width="100%" height="300px" image="theme-wm/staff3.jpg" />
						<Stack direction="column" align="left">
							<Heading size="5">Alex Bryan</Heading>
							<Heading size="6" uppercase>Product Lead</Heading>
						</Stack>
					</Stack>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Stack direction="column" align="left" space="1">
						<Images width="100%" height="300px" image="theme-wm/staff2.jpg" />
						<Stack direction="column" align="left">
							<Heading size="5">Colbie Travers</Heading>
							<Heading size="6" uppercase>Product Designer</Heading>
						</Stack>
					</Stack>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Stack direction="column" align="left" space="1">
						<Images width="100%" height="300px" image="theme-wm/staff5.jpg" />
						<Stack direction="column" align="left">
							<Heading size="5">Lexi-May Carey</Heading>
							<Heading size="6" uppercase>Product Designer</Heading>
						</Stack>
					</Stack>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Stack direction="column" align="left" space="1">
						<Images width="100%" height="300px" image="theme-wm/staff7.jpg" />
						<Stack direction="column" align="left">
							<Heading size="5">Asher Carpenter</Heading>
							<Heading size="6" uppercase>Product Developer</Heading>
						</Stack>
					</Stack>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Stack direction="column" align="left" space="1">
						<Images width="100%" height="300px" image="theme-wm/staff1.jpg" />
						<Stack direction="column" align="left">
							<Heading size="5">Elle Brewer</Heading>
							<Heading size="6" uppercase>Product Developer</Heading>
						</Stack>
					</Stack>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Stack direction="column" align="left" space="1">
						<Images width="100%" height="300px" image="theme-wm/staff8.jpg" /> image="
						<Stack direction="column" align="left">
							<Heading size="5">Stacy Bryan</Heading>
							<Heading size="6" uppercase>Intern</Heading>
						</Stack>
					</Stack>
				</GridContainer>
			</Grid>
		</Section>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/projects/windermere/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
}

</script>

<style lang="scss">
</style>
